<template>
  <div class="px-0">
    
    <!-- <v-container
    height="400"
    width="300"
    class="ma-0"
    > -->

<!-- Logo -->
    <v-row>
      <v-col cols="6" class="pl-12">
        <v-img
        src="@/assets/img/logo/Teman Logo.png"
        width="230"
        ></v-img>
      </v-col>

      <v-col cols="6" class="pr-12">
        <v-img
        src="@/assets/img/logo/logo.png"
        width="190"
        class="ml-auto"
        ></v-img>
      </v-col>
    </v-row>

    <div>
      <v-row class="mr-5">
        <v-spacer></v-spacer>

          <v-btn
          v-show="$store.getters.user.role_detail == undefined? false :  $store.getters.user.role_detail.role == 'admin'? true : false"
          class="mr-5" 
          color="success" 
          @click="goToPage('/UserManagement')"
          dense
          >
              Admin
          </v-btn>

          <v-btn 
          class="mr-5" 
          color="success" 
          @click="signout()"
          >
              Logout
          </v-btn>

      </v-row>
    </div>

    <!-- <v-row>
      <v-col cols="6">
        <v-btn>
          Admin
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-btn @click="signout()">
          Logout
        </v-btn>
      </v-col>
    </v-row> -->

<!-- Background Image Total Expressway Maintenance Management System -->
    <v-row :class="$vuetify.breakpoint.lgAndUp? 'mt-6' : 'mt-6'">

      <v-col>
        <!-- IF  -->
        <v-img
        v-if="$vuetify.breakpoint.lgAndUp"
        src="@/assets/img/wallpaper/temanwtext.png"
        ></v-img>


        <v-img
        v-else-if="$vuetify.breakpoint.mdAndDown"
        src="@/assets/img/wallpaper/expressweb.png"
        ></v-img>
      </v-col>

      <!-- <v-col 
      v-show="$vuetify.breakpoint.mdAndDown"
      cols="5" 
      class="pa-12 totalExpressWeb"
      >

        <h1 
        style="font-size: 70px; 
        width: 800px;"
        class="pb-10 white--text"
        >
        Total Expressways Maintenance Management System
        </h1> -->

        <!-- <v-col cols="11">
          <span 
          style="font-size: 20px;"
          class="white--text"
          >
          Total Expressways Maintenance Management System or <b>TEMAN</b> is a decision support tool software that was
          developed to facilitate management control of highway inventories or assets, their condition and related
          maintenance activities.
          </span>
        </v-col> -->

      <!-- </v-col> -->
    </v-row>

<!-- Discover Our Purpose -->
    <v-container>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '8' : '12'">
          <h1 :style="$vuetify.breakpoint.lgAndUp ? 'font-size:70px' : 'font-size:30px'" class="primary--text">Discover Our Purpose</h1>
          <!-- <h1 style="font-size:70px;" class="primary--text">Discover Our Purpose</h1> -->

          <span :style="$vuetify.breakpoint.lgAndUp ? 'font-size:20px' : 'font-size:15px'">Total Expressways Maintenance Management System or TEMAN is a decision support tool software that was developed to facilitate the management control of highway 
            inventories or assets, their condition and related maintenance activities. TEMAN has always play a vital role in fulfilling PLUS obligations to Concession Agreement 
            requirement particularly during the handing over of some highway stretches back to government in 2017.
          </span>
          <br><br>
          <span :style="$vuetify.breakpoint.lgAndUp ? 'font-size:20px' : 'font-size:15px'">
            Since its inception on client-server architecture way back in 1998, TEMAN has undergone series of achievements from migration to web-based up to the recent development 
            of Application Programming Interface (APIs) to facilitate interfacing of TEMAN with other applications. 
          </span>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'">
          <v-img
              src="@/assets/img/wallpaper/discover.png"
            ></v-img>
        </v-col>
      </v-row>
    </v-container>

<!-- TEMAN Modules -->
    <v-container>
      <v-row
      align="center"
      justify="center"
      class="pa-5"
      >
        <h1 :style="$vuetify.breakpoint.lgAndUp ? 'font-size:70px' : 'font-size:30px'" class="primary--text">TEMAN Modules</h1>
      </v-row>
        
      <v-row 
      align="center"
      justify="center"
      >
        <v-col cols="8" align="center" justify="center">
          <span :style="$vuetify.breakpoint.lgAndUp ? 'font-size:20px' : 'font-size:15px'">As a Master Repository, TEMAN captures all highway assets inventories (with some assets conditions) into three main categories:</span>
        </v-col>

      </v-row>

      <v-row class="card-module">

        <v-col :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'" align="center" justify="center">
          <v-card
          height="600"
          width="400"
          class="mx-auto"
          >

            <div class="pa-3 align-center justify-center">
              <v-img
              src="@/assets/img/wallpaper/cs.png"
              width="400px"
              height="250px"
              ></v-img>
              <!-- <v-card-media
              src="@/assets/img/logo/road.png"
              width="250px"
              height="250px"
              >
              </v-card-media> -->
              <!-- <v-avatar>
                <v-icon>
                  mdi-traffice-cone
                </v-icon>
              </v-avatar> -->

              <v-col>
              
                <v-card-title class="justify-center">
                  <h2 class="primary--text">Civil & Structure</h2>
                </v-card-title>

              </v-col>

                <v-card-text class="pb-9">
                  <span :style="$vuetify.breakpoint.lgAndUp ? 'font-size:20px' : 'font-size:15px'">Major highway assets e.g. Slope, Bridge, Culvert, Tunnel, Signboard, Buildings etc.
                  </span>
                </v-card-text>

              <v-col cols="auto">

                <v-btn 
                :disabled="loading_cs == true? false : $store.getters.user.role_detail == undefined? false : $store.getters.user.role_detail.cs == false? true : false"
                :class="loading_cs == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.cs == false ? 'module-button-off' : 'module-button'"
                @click="discoverMore('CS')"
                x-large
                color="primary"
                rounded
                :loading="loading_cs"
                >
                  Discover More
                </v-btn>

              </v-col>
            </div>

          </v-card>
        </v-col>

        <v-col :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'" align="center" justify="center">
          <v-card
          height="600"
          width="400"
          class="mx-auto"
          >

            <div class="pa-2 align-center justify-center">
              <v-img
              src="@/assets/img/wallpaper/me.png"
              width="350px"
              height="250px"
              ></v-img>
              <!-- <v-card-media
              src="@/assets/img/logo/road.png"
              width="250px"
              height="250px"
              >
              </v-card-media> -->
              <!-- <v-avatar>
                <v-icon>
                  mdi-traffice-cone
                </v-icon>
              </v-avatar> -->

              <v-col>
              
                <v-card-title class="justify-center">
                  <h2 class="primary--text">Mechanical & Electrical</h2>
                </v-card-title>

              </v-col>

                <v-card-text class="pb-8">
                  <span :style="$vuetify.breakpoint.lgAndUp ? 'font-size:20px' : 'font-size:15px'">Equipment e.g. VMS, Lighting System, CCTVs etc.
                  </span>
                </v-card-text>

              <v-col cols="auto">

                <v-btn 
                :disabled="loading_me == true? false : $store.getters.user.role_detail == undefined? false : $store.getters.user.role_detail.wme == false? true : false"
                :class="loading_me == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.wme == false ? 'module-button-off' : 'module-button'"
                @click="discoverMore('ME')"
                x-large
                color="primary"
                rounded
                :loading="loading_me"
                >
                  Discover More
                </v-btn>

              </v-col>
            </div>

          </v-card>
        </v-col>

        <v-col :cols="$vuetify.breakpoint.lgAndUp? '4' : '12'" align="center" justify="center">
          <v-card
          height="600"
          width="400"
          class="mx-auto flexcard"
          >

            <div class="pa-2 align-center justify-center">
              <v-img
              src="@/assets/img/wallpaper/rf.png"
              width="350px"
              height="250px"
              ></v-img>
              <!-- <v-card-media
              src="@/assets/img/logo/road.png"
              width="250px"
              height="250px"
              >
              </v-card-media> -->
              <!-- <v-avatar>
                <v-icon>
                  mdi-traffice-cone
                </v-icon>
              </v-avatar> -->

              <v-col>
              
                <v-card-title class="justify-center">
                  <h2 class="primary--text">Road Furniture</h2>
                </v-card-title>

              </v-col>

                <v-card-text>
                  <span :style="$vuetify.breakpoint.lgAndUp ? 'font-size:20px' : 'font-size:15px'">For all fixtures that provides information or safety for the highway users, e.g. Road line marking, guardrails, fencing etc.
                  </span>
                </v-card-text>

              <v-col cols="auto">

                <v-btn 
                :disabled="loading_rf == true? false : $store.getters.user.role_detail == undefined? false : $store.getters.user.role_detail.rf == false? true : false"
                :class="loading_rf == true? 'module-button' : $store.getters.user.role_detail == undefined? 'module-button' : $store.getters.user.role_detail.rf == false ? 'module-button-off' : 'module-button'"
                @click="discoverMore('RF')"
                x-large
                color="primary"
                rounded
                :loading="loading_rf"
                >
                  Discover More
                </v-btn>

              </v-col>
            </div>

          </v-card>
        </v-col>
   
      </v-row>
    </v-container>

<!-- IFRAME -->
    <!-- <v-container >
      <template>
        <iframe src='' id='iframe_cs' ref="iframe_cs" width=1000 height=300></iframe> <br>
        <iframe src='' id='iframe_rf' ref="iframe_rf" width=1000 height=300></iframe> <br>
        <iframe src='' id='iframe_wme' ref="iframe_wme" width=1000 height=300></iframe> <br>
      </template>
    </v-container> -->
    

  </div>
</template>

<script>
import axios from 'axios';
// import { bus } from '@/main';

export default {

  data(){
    return {
      
      iframePage: false,
      modeLandingPage: "",

      detailUserRole: null,
      detailUserCS: null,
      detailUserWME: null,
      detailUserRF: null,

      loading_cs: false,
      loading_me: false,
      loading_rf: false,
      
    }
  },

  mounted() {

    this.loadLinkButtonRedirect();
    // this.loadIFrame();

    console.log(this.$store.getters.user);
    
  },

  methods: {

    signout()
    {
      this.$store.dispatch("signOut");
    },

    loadLinkButtonRedirect(){

      this.loading_cs = true;
      this.loading_me = true;
      this.loading_rf = true;

      setTimeout(() => {

        if(this.$store.getters.user.role_detail != undefined){

          this.loading_cs = false;
          this.loading_me = false;
          this.loading_rf = false;

        }

      }, 3000)

    },

    // loadIFrame(){

    //   setTimeout(() => {

    //     console.log(this.$refs.iframe_wme);
        
    //     this.$refs.iframe_cs.src = "https://donutgebu.shaz.my/webteman?id_token=" + this.$store.getters.user.id_token;
    //     this.$refs.iframe_rf.src = "https://donutgebu.shaz.my/rf/index.php/auth/login?id_token=" + this.$store.getters.user.id_token;
    //     this.$refs.iframe_wme.src = "https://donutgebu.shaz.my/wme?id_token=" + this.$store.getters.user.id_token;

    //     // this.test()

    //     // console.log(this.$refs);
    //     // this.checkingStateLoginiFrame();

    //     console.log(this.$refs.iframe_wme);

    //     console.log("HAIIIIIIIIIIIIIIIII");

    //   }, 5000)

    // },

    // test(){
    //   console.log("HELLOOOOOO!!!!!");
    // },

    // checkingStateLoginiFrame(){

    //   let myInterval = setInterval(() => {
        
    //     console.log("in check for iframe login (every 5 seconds)");

    //     this.loadCheck();

    //   }, 5000);

      

    // },

    // loadCheck(){

    //   axios.get(this.vueUrl + 'callapi/cekloginload.php', {
    //     headers: {
    //       Authorization: 'Bearer ' + this.$store.getters.user.id_token,
    //     },
    //   })
    //   .then((response) => {
        
    //     let data = response.data;

    //     console.log("bbbbbbbbbbb",data);

    //     // this.loading_cs = !data.pjm_loaded;
    //     // this.loading_me = !data.wme_loaded;
    //     // this.loading_rf = !data.rf_loaded;

        
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //     this.loadingBtnCaptcha = false;

    //   })

    // },

    discoverMore(menu)
    {
      console.log(menu);
      
      if(menu == "CS")
      {
        window.location.href ="https://donutgebu.shaz.my/webteman/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
        // window.location.href ="https://donutgebu.shaz.my/webteman/login2";
        // window.location.href ="https://donutgebu.shaz.my/webteman/?id_token=" + this.$store.getters.user.id_token;
      }
      else if(menu == "ME")
      {
        window.location.href ="https://donutgebu.shaz.my/wme/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
        // window.location.href ="https://donutgebu.shaz.my/wme/home";
        // window.location.href ="https://donutgebu.shaz.my/wme?id_token=" + this.$store.getters.user.id_token;
      }
      else if (menu == "RF") 
      {
        window.location.href = "https://donutgebu.shaz.my/rf/index.php/apis1/redirectloginpage?id_token="+this.$store.getters.user.id_token;
        // window.location.href = "https://donutgebu.shaz.my/rf/index.php/welcome";
        // window.location.href = "https://donutgebu.shaz.my/rf/index.php/auth/login?id_token=" + this.$store.getters.user.id_token;
      }

      // console.log(this.$store.getters.user.id_token);
    },

    // iframeonloadcek(thisiframe)
    // {
    //    console.log(thisiframe);
    // },

    iframeonloadcek(thisiframe)
    {
        if(thisiframe.id=='iframe_cs')
        {
            document.getElementById('cs_button').innerHTML = 'plus Civil structure (cs)';

            if(thisiframe.src.indexOf('logout')!=-1) this.kira_3sistem_logout = this.kira_3sistem_logout + 1;
        }

        if(thisiframe.id=='iframe_rf')
        {
            document.getElementById('rf_button').innerHTML = 'plus RF (road furniture)';
            if(thisiframe.src.indexOf('logout')!=-1) this.kira_3sistem_logout = this.kira_3sistem_logout + 1;
        }

        if(thisiframe.id=='iframe_wme')
        {
            document.getElementById('wme_button').innerHTML = 'plus WME (mechanical engineering)';
            if(thisiframe.src.indexOf('logout')!=-1) this.kira_3sistem_logout = this.kira_3sistem_logout + 1;
        }

        if(this.kira_3sistem_logout==3)
        {   
            alert('ok tiga2 dh logout');
            location.href = '/plus/homelogin.html';
        }

    }
     
  },

};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

div.totalExpressWeb.col.col-5
{
  position: relative;
  bottom: 500px;
  margin-bottom: -890px;
  margin-left: 100px;
}

// .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
// {
//   width: 240px !important;
//   background: rgb(9,86,164);
//   background: linear-gradient(180deg, rgba(9,86,164,1) 0%, rgba(10,20,103,1) 100%);
// }

// .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover
// {
//   width: 240px !important;
//   background: rgb(4,153,75);
//   background: linear-gradient(180deg, rgba(4,153,75,1) 0%, rgba(14,74,42,1) 100%);
// }
.module-button{
  width: 240px !important;
  background: rgb(9,86,164);
  background: linear-gradient(180deg, rgba(9,86,164,1) 0%, rgba(10,20,103,1) 100%);
}

.module-button:hover{
  width: 240px !important;
  background: rgb(4,153,75);
  background: linear-gradient(180deg, rgba(4,153,75,1) 0%, rgba(14,74,42,1) 100%);
}

.module-button-off{
  width: 240px !important;
}

h2.primary--text
{
  white-space: pre-wrap;
  word-break: break-word;
}

</style>


